<template>
  <div>
    <b-card title="Habbil">
      <b-card-text>La plataforma de gestión de conciliaciones
        esta diseñada para ejecutar conciliaciones entre dos fuentes
        orígenes de datos, basados en la configuración de reglas y
        procesos los cuales permitirán plasmar las particularidades
        de cada negocio según lo requiera cada comparación. </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
